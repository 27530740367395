import React from "react";
import Logo from "./Logo";

export default function Header() {
  return (
    <header className="w-full fixed z-50 items-center justify-center">
      <div className="relative before:pointer-events-none before:absolute before:inset-0 before:border-b before:border-transparent before:[background:linear-gradient(to_right,theme(colors.gray.800),theme(colors.gray.700),theme(colors.gray.800))_border-box] before:[mask-composite:exclude_!important] before:[mask:linear-gradient(white_0_0)_padding-box,_linear-gradient(white_0_0)] after:inset-0 after:z-200 backdrop-blur-xl">
        <div className="flex h-20 mx-auto max-w-6xl px-4 sm:px-6">
          <div className="flex">
            <Logo />
          </div>

          <ul className="flex flex-1 items-center justify-end gap-1 sm:gap-2">
            <li>
              <a
                href="#home"
                className="btn-sm relative bg-gradient-to-b from-gray-800 to-gray-800/60 bg-[length:100%_100%] bg-[bottom] py-[2px] sm:py-[4px] text-xs sm:text-sm text-gray-300 before:pointer-events-none before:absolute before:inset-0 before:rounded-[inherit] before:border before:border-transparent before:[background:linear-gradient(to_right,theme(colors.gray.800),theme(colors.gray.700),theme(colors.gray.800))_border-box] before:[mask-composite:exclude_!important] before:[mask:linear-gradient(white_0_0)_padding-box,_linear-gradient(white_0_0)] hover:bg-[length:100%_150%] px-2 sm:px-3"
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="#aboutus"
                className="btn-sm relative bg-gradient-to-b from-gray-800 to-gray-800/60 bg-[length:100%_100%] bg-[bottom] py-[2px] sm:py-[4px] text-xs sm:text-sm text-gray-300 before:pointer-events-none before:absolute before:inset-0 before:rounded-[inherit] before:border before:border-transparent before:[background:linear-gradient(to_right,theme(colors.gray.800),theme(colors.gray.700),theme(colors.gray.800))_border-box] before:[mask-composite:exclude_!important] before:[mask:linear-gradient(white_0_0)_padding-box,_linear-gradient(white_0_0)] hover:bg-[length:100%_150%] px-2 sm:px-3"
              >
                About Us
              </a>
            </li>
            <li>
              <a
                href="#products"
                className="btn-sm relative bg-gradient-to-b from-gray-800 to-gray-800/60 bg-[length:100%_100%] bg-[bottom] py-[2px] sm:py-[4px] text-xs sm:text-sm text-gray-300 before:pointer-events-none before:absolute before:inset-0 before:rounded-[inherit] before:border before:border-transparent before:[background:linear-gradient(to_right,theme(colors.gray.800),theme(colors.gray.700),theme(colors.gray.800))_border-box] before:[mask-composite:exclude_!important] before:[mask:linear-gradient(white_0_0)_padding-box,_linear-gradient(white_0_0)] hover:bg-[length:100%_150%] px-2 sm:px-3"
              >
                Products
              </a>
            </li>
            <li>
              <a
                href="#contactus"
                className="btn-sm bg-gradient-to-t from-indigo-600 to-indigo-500 bg-[length:100%_100%] bg-[bottom] py-[2px] sm:py-[4px] text-xs sm:text-sm text-white shadow-[inset_0px_1px_0px_0px_theme(colors.white/.16)] hover:bg-[length:100%_150%] px-2 sm:px-3"
              >
                Contact Us
              </a>
            </li>
            {/* Updated Dashboard Button */}
            <li>
              <a
                href="https://dashboard.hygaar.com/login"
                className="btn-sm bg-white bg-[length:100%_100%] bg-[bottom] py-[2px] sm:py-[4px] text-xs sm:text-sm text-indigo-600 shadow-[inset_0px_1px_0px_0px_theme(colors.white/.16)] hover:bg-[length:100%_150%] px-2 sm:px-3"
                target="_blank" // Optional: opens in a new tab
                rel="noopener noreferrer" // Security measure when using target="_blank"
              >
                Login
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}
