import React, { useState, useEffect, useMemo, useRef } from "react";
import { motion } from "framer-motion";
import { FaUser } from "react-icons/fa"; // Import user and robot icons

const Chatbot = () => {
  const [currentMessage, setCurrentMessage] = useState(null); // Single visible message
  const [messages, setMessages] = useState([]); // Conversation messages
  const [input, setInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [messageIndex, setMessageIndex] = useState(0);
  const [isVideoPlaying, setIsVideoPlaying] = useState(true); // Start with video
  const videoImageCycle = useRef(null); // To clear intervals if necessary
  useEffect(() => {
    // SwituseEffect(() => {
    const cycle = () => {
      setIsVideoPlaying((prev) => !prev);
    };

    // Start a loop: 5 seconds for video, 2 seconds for the image
    videoImageCycle.current = setInterval(cycle, 7000);

    // Cleanup on unmount
    return () => clearInterval(videoImageCycle.current);
  }, []);
  // Reference to the message container
  const messagesEndRef = useRef(null);

  // Static bot messages
  const botMessages = useMemo(
    () => [
      "Hi my name is Amy! I am a virtual front desk for HyGaar.",
      "Feel free to ask me anything!",
      "I'm here to guide you. Just type your message below.",
    ],
    []
  );

  // Handle scroll to trigger the prompt
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowPrompt(true);
      } else {
        setShowPrompt(false);
        setMessageIndex(0); // Reset message index when scrolling back up
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Display one message at a time in the prompt
  useEffect(() => {
    if (showPrompt && messageIndex < botMessages.length) {
      const timeout = setTimeout(() => {
        const newMessage = {
          user: false,
          text: botMessages[messageIndex],
          isPrompt: true,
        };
        setCurrentMessage(newMessage); // Display current message
        setMessages((prev) => [...prev, newMessage]); // Add to history (for scrolling or debugging)
        setMessageIndex((prevIndex) => prevIndex + 1); // Move to the next message
      }, 3000); // Show each message every 3 seconds

      return () => clearTimeout(timeout);
    }
  }, [showPrompt, messageIndex, botMessages]);

  const sendMessage = async () => {
    const apiUrl2 = process.env.REACT_APP_API_URL2;

    if (!input.trim()) return;

    const userMessage = { user: true, text: input, isPrompt: false };
    setMessages((prev) => [...prev, userMessage]);
    setInput("");
    setIsTyping(true);

    try {
      const response = await fetch(apiUrl2, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message: input }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch response");
      }

      const data = await response.json();
      const botResponse = {
        user: false,
        text: data.reply,
        isPrompt: false,
      };

      setMessages((prev) => [...prev, botResponse]);
      setCurrentMessage(botResponse); // Display current message
    } catch (error) {
      const errorMessage = {
        user: false,
        text: "I'm having trouble connecting to the server. Please try again later.",
        isPrompt: false,
      };
      setMessages((prev) => [...prev, errorMessage]); // Show error message
    } finally {
      setIsTyping(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  // React Icons for user and bot avatars
  const userIcon = <FaUser className="w-6 h-6 " />;
  const botIcon = (
    <img
      src="/images/Amy_dp.png" // Path to your high-resolution image
      alt="Bot Icon"
      className="w-6 h-6 rounded-full object-cover object-center  shadow-md"
      style={{
        transform: "scale(1.5)", // Adjust this for zoom level
        transformOrigin: "center", // Keep the zoom centered
      }}
    />
  );



  // Scroll to the bottom whenever messages update
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <motion.section
      className="relative mx-auto max-w-6xl px-4 sm:px-6 mt-20 sm:mt-0 z-30"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1.2, ease: "easeOut" }}
    >
      {/* Background overlay when chat is open */}
      {showChat && (
        <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-30"></div>
      )}

      {!showChat && (
        <div className="fixed bottom-6 right-8 flex flex-col items-end space-y-2">
          {/* Bot Message (only one at a time) */}
          {currentMessage && (
            <motion.div
              className={`bg-gray-800 text-white p-3 rounded-lg shadow-md max-w-xs`}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              {currentMessage.text}
            </motion.div>
          )}

          {/* Chatbot Button */}
        <motion.button
  onClick={() => setShowChat(true)}
  className="p-3 bg-indigo-500 text-white rounded-full shadow-xl flex items-center justify-center"
  initial={{ scale: 1, opacity: 0.9 }}
  animate={{
    scale: [1, 1.1, 1],
    boxShadow: [
      "0 0 10px rgba(99, 102, 241, 0.5)",
      "0 0 20px rgba(99, 102, 241, 0.7)",
      "0 0 10px rgba(99, 102, 241, 0.5)",
    ],
  }}
  transition={{
    duration: 2,
    repeat: Infinity,
    repeatType: "loop",
  }}
  whileHover={{
    scale: 1.2,
    boxShadow: "0 0 25px rgba(99, 102, 241, 0.9)",
  }}
>
  <div
    className="relative w-11 h-11 rounded-full overflow-hidden"
    style={{
      transform: "scale(1.5)", // Adjust for zoom level
      transformOrigin: "center", // Keep zoom centered
    }}
  >
    <motion.div
      initial={{ opacity: isVideoPlaying ? 1 : 0 }}
      animate={{ opacity: isVideoPlaying ? 1 : 0 }}
      transition={{ duration: 0.001 }} // Adjust transition duration for smooth fade
      className="absolute inset-0"
    >
      {isVideoPlaying && (
        <video
          src="/images/Amy_minimax.mp4" // Path to your video
          autoPlay
          muted
          loop={false}
          className="w-full h-full object-cover object-center"
        />
      )}
    </motion.div>
    <motion.div
      initial={{ opacity: isVideoPlaying ? 0 : 1 }}
      animate={{ opacity: isVideoPlaying ? 0 : 1 }}
      transition={{ duration: 0.01 }} // Adjust transition duration for smooth fade
      className="absolute inset-0"
    >
      {!isVideoPlaying && (
        <img
          src="/images/Amy_dp.png" // Path to your high-resolution image
          alt="Bot Icon"
          className="w-full h-full object-cover object-center"
        />
      )}
    </motion.div>
  </div>
</motion.button>


        </div>
      )}

      {showChat && (
        <motion.div
          className="fixed bottom-6 right-0 sm:right-8 p-3 bg-gradient-to-br from-gray-800 via-gray-900 to-black text-white rounded-lg shadow-2xl sm:w-full sm:max-w-[90%] lg:max-w-[40vw] max-h-[85vh] overflow-hidden flex flex-col z-30"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          {/* Chat Header with AmyBot and Robot Icon */}
          <div className="flex items-left space-x-3 mb-4 pb-2 border-b border-gray-600">
            <div className="w-10 h-10 rounded-full flex items-center justify-center">
              {botIcon}
            </div>
            <span className="text-2xl font-semibold text-gray-200">Amy</span>
          </div>

          {/* Close Button */}
          <button
            onClick={() => {
              setShowChat(false); // Close the chat
              setCurrentMessage(null); // Clear current message to prevent showing the bot's last message
            }}
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-200 focus:outline-none transition-colors duration-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          {/* Chat Messages */}
          <div
            className="overflow-y-auto space-y-3 mb-4 max-h-[60vh] pr-2 flex flex-col custom-scrollbar"
            style={{
              scrollbarWidth: "none", // For Firefox
              msOverflowStyle: "none", // For IE and Edge
            }}
          >
            {messages
              .filter((msg) => !msg.isPrompt) // Exclude messages marked as "prompt"
              .map((msg, index) => (
                <motion.div
                  key={index}
                  className={`flex items-start ${msg.user ? "self-end flex-row-reverse" : "self-start"
                    }`}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  {/* Profile Image */}
                  {msg.user ? (
                    <div className="w-9 h-9 rounded-full flex items-center justify-center bg-gray-500 text-white">
                      {userIcon}
                    </div>
                  ) : (
                    <div className="w-10 h-10 rounded-full flex items-center justify-center">
                      {botIcon}
                    </div>
                  )}

                  {/* Message */}
                  <div
                    className={`${msg.user
                      ? "bg-gradient-to-r from-indigo-600 to-indigo-500 text-white rounded-lg px-4 py-2 shadow-lg mr-2"
                      : "bg-gray-700 text-white rounded-lg px-4 py-2 shadow-lg ml-2"
                      } max-w-[80%] break-words`}
                  >
                    {msg.text}
                  </div>
                </motion.div>
              ))}
            {isTyping && (
              <div className="self-start text-gray-400 px-4 py-2 rounded-lg">
                <span className="animate-pulse">...</span>
              </div>
            )}
            {/* Scroll to the bottom */}
            <div ref={messagesEndRef} />
          </div>

          {/* Input Section */}
          <div className="flex items-center border-t border-gray-600 pt-4 mt-4">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyDown} // Listen for Enter key press
              placeholder="Ask me anything..."
              className="flex-1 text-black px-4 py-3 rounded-l-lg bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-all duration-300"
            />
            <button
              onClick={sendMessage}
              className="bg-indigo-600 hover:bg-indigo-700 px-4 py-3 text-white rounded-r-lg shadow-md transition-transform transform hover:scale-105 focus:outline-none"
            >
              Send
            </button>
          </div>
        </motion.div>
      )}
    </motion.section>
  );
};

export default Chatbot;
